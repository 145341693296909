import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getHobbiesHttp=async(data, token)=>{
    var res=await httpRequestGet('/hobbies-paginate/', data, token)
    return(res)
}
export const setHobbiesHttp=async(data, token)=>{
    var res=await httpRequestPost('/hobbies/create/', data, token)
    return(res)
}
export const editHobbiesHttp=async(id, data, token)=>{
    var res=await httpRequestPatch('/hobbies/', data, token, id)
    return(res)
}
export const deleteHobbiesHttp=async(data, token)=>{
    var res=await httpRequestDelete('/hobbies/', data, token)
    return(res)
}
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import dayjs from 'dayjs'

export default function UsersLikesFilter({
    created_at__date__gte,
    created_at__date__lte,
    setcreated_at__date__gte,
    setcreated_at__date__lte,
    closeModal,
    getData,
    setpage
}) {
    const [form] = Form.useForm()
    const [reset_bol, setreset_bol]=useState(false)
    
    useEffect(()=>{
        setValuesForm()
        if(reset_bol){
            getData()
        setreset_bol(false)
        }
    }, [created_at__date__gte,created_at__date__lte,reset_bol])
  
    const setValuesForm=()=>{
         form.setFieldsValue({
            
            "created_at__date__gte":created_at__date__gte!==null?dayjs(created_at__date__gte, 'DD.MM.YYYY'):null,
            "created_at__date__lte":created_at__date__lte!==null?dayjs(created_at__date__lte, 'DD.MM.YYYY'):null,
           
        })
    }
     const resetFilter=()=>{
       
        setcreated_at__date__gte(null)
        setcreated_at__date__lte(null)
      
        setreset_bol(true)
     }
   
  return (
    <div> <Form
    name="add"
    form={form}
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
   
  >
    <Row>
   
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Like bosilgan sana (dan)"
      name="created_at__date__gte"
      
    >
     <DatePicker onChange={(val=>{setcreated_at__date__gte(val!==null?val.format("DD.MM.YYYY"):null)})}  className='date_input' format="DD.MM.YYYY"/>
    </Form.Item></Col>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Like bosilgan sana (gacha)"
      name="created_at__date__lte"
      
    >
     <DatePicker onChange={(val=>{setcreated_at__date__lte(val!==null?val.format("DD.MM.YYYY"):null)})} className='date_input' format="DD.MM.YYYY"/>
    </Form.Item></Col>
    </Row>
    <Form.Item
      wrapperCol={{
        span: 24,
      }}
className='btn_form_div'
    >
        <div className='btn_box'>
        <Button onClick={closeModal} htmlType="button">
        Yopish
      </Button>
      <Button onClick={resetFilter} htmlType="button">
        Tozalash
      </Button>
      <Button onClick={getData} type="primary"  htmlType="button">
        Qidirish
      </Button>
      
      </div>
    </Form.Item>
   
  </Form></div>
  )
}

import { httpRequestDelete, httpRequestGet } from "../Host"

export const getBlockedEmailsHttp=async(data, token)=>{
    var res=await httpRequestGet('/admins/block-users/', data, token)
    return(res)
}

export const deleteBlockedEmailsHttp=async(data, token)=>{
    var res=await httpRequestDelete('/admins/block-user/', data, token)
    return(res)
}
import React, { useEffect } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import { useTranslation } from 'react-i18next'
import { useHref } from 'react-router-dom';
import { useStore } from '../store/Store'
import { checkUsersData } from '../components/Checkers'
import {Routes, Route} from 'react-router-dom'
import Faq from './Faq';
import Hobby from './Hobby';
import BlockReason from './BlockReason';
import Users from './Users';
import OneUserSelf from './OneUserSelf';
import BlockedEmails from './BlockedEmails';
import UserLikes from './UserLikes';
import UserBlock from './UserBlock';
import UserLikesOne from './UserLikesOne';
import UserBlockOne from './UserBlockOne';
export default function Home() {
   const {t} =useTranslation()
   const history = useHref();
   
  return (
    <div className='bigBox'>
        <div className='bigMenu'>
            <Menu/>
        </div>
        <div className='bigContent'>
          
        <Routes>
            <Route path=""/>
            <Route path="faq" element={<Faq/>}/>
            <Route path="hobby" element={<Hobby/>}/>
            <Route path="blocked-emails" element={<BlockedEmails/>}/>
            <Route path="liked-user" element={<UserLikes/>}/>
            <Route path="blocked-user" element={<UserBlock/>}/>
            <Route path="users" element={<Users/>}/>
            
            <Route path="users/:id" element={<OneUserSelf/>}/>
            <Route path="users-liked-one/:id" element={<UserLikesOne/>}/>
            <Route path="users-blocked-one/:id" element={<UserBlockOne/>}/>
            <Route path="blocking-reasons" element={<BlockReason/>}/>
        </Routes>
    
        </div>
    </div>
  )
}

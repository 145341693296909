import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { getCountriesHttp, getHobbiesHttp, getStatesHttp } from '../host/requests/Countries'
import { useStore } from '../store/Store'
import dayjs from 'dayjs'

export default function UserFilter({
    country,
    state,
    hobbies,
    gender,
    created_at__date__gte,
    created_at__date__lte,
    _age__gte,
    _age__lte,
    setcountry,
    setstate,
    sethobbies,
    setgender,
    setcreated_at__date__gte,
    setcreated_at__date__lte,
    set_age__gte,
    set_age__lte,
    closeModal,
    getData,
    setpage
}) {
    const [form] = Form.useForm()
    const [hobby_data, sethobby_data]=useState(null)
    const [reset_bol, setreset_bol]=useState(false)
    const [countries_data, setcountries_data]=useState(null)
    const [state_data, setstate_data]=useState([])
    const token_access=useStore(state=>state.token_access)
    useEffect(()=>{
        if(hobby_data===null){
            getHobbies()
        }
        if(countries_data===null){
            getCountries()
        }
        setValuesForm()
        if(reset_bol){
            
            getData()
        setreset_bol(false)
        }
    }, [country,state,hobbies,gender,created_at__date__gte,created_at__date__lte,_age__gte,_age__lte, reset_bol])
  
    const setValuesForm=()=>{
         form.setFieldsValue({
            "country":country,
            "state":state,
            "hobbies":hobbies,
            "gender":gender,
            "created_at__date__gte":created_at__date__gte!==null?dayjs(created_at__date__gte, 'DD.MM.YYYY'):null,
            "created_at__date__lte":created_at__date__lte!==null?dayjs(created_at__date__lte, 'DD.MM.YYYY'):null,
            "_age__gte":_age__gte,
            "_age__lte":_age__lte
        })
    }
     const resetFilter=()=>{
        setcountry(null)
        setstate(null)
        sethobbies(null)
        setgender(null)
        setcreated_at__date__gte(null)
        setcreated_at__date__lte(null)
        set_age__gte(null)
        set_age__lte(null)
        setreset_bol(true)
     }
    const getHobbies=async()=>{
        try{
            var res=await getHobbiesHttp(null, token_access)
            sethobby_data(res.data)
       }catch(error){
            console.log(error)
       }

    }
    const getCountries=async()=>{
        try{
            var res=await getCountriesHttp(null, token_access)
            setcountries_data(res.data)
       }catch(error){
            console.log(error)
       }
    }
    const getState=async(val)=>{
        setstate(null) 
        setcountry(val)
        
        try{
             var res=await getStatesHttp({country:val}, token_access)
             setstate_data(res.data)
        }catch(error){
             console.log(error)
        }
        
      }
      const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  return (
    <div> <Form
    name="add"
    form={form}
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
   
  >
    <Row>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Davlat"
      name="country"
    >
    <Select
    className='select' 
    showSearch
    
    optionFilterProp="children"
    filterOption={filterOption}
    
    onChange={getState}
    options={countries_data!==null?countries_data.map((item, ket)=>({label:item[`name_uz`], value:item.id})):[]}
    />
    </Form.Item></Col>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Viloyat"
      name="state"
    >
    <Select
    className='select' 
    showSearch
    
    optionFilterProp="children"
    filterOption={filterOption}
    
    onChange={(val)=>{setstate(val); console.log(val);}}
    options={state_data!==null?state_data.map((item, ket)=>({label:item[`name_uz`], value:item.id})):[]}
    />
    </Form.Item></Col>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Qiziqishlar"
      name="hobbies"
    >
    <Select
    className='select' 
    showSearch
    
    optionFilterProp="children"
    filterOption={filterOption}
    onChange={(val)=>{sethobbies(val); console.log(val)}}
    options={hobby_data!==null?hobby_data.map((item, ket)=>({label:item[`name_uz`], value:item.id})):[]}
    />
    </Form.Item></Col>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Jins"
      name="gender"
      
    >
     <Select
      className='select' 
      
      onChange={(val)=>{setgender(val); console.log(val)}}
      options={[
        {
          value: 'male',
          label: "Erkak",
        },
        {
          value: 'female',
          label: "Ayol",
        },
        {
          value: 'rather not say',
          label: "Boshqa",
        },
       
      ]}
    />
    </Form.Item></Col>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Yosh (dan)"
      name="_age__gte"
      
      rules={[
        {
            type: 'number',
            min:0,
            max:_age__lte===null?100:_age__lte,
            message: `0 va ${_age__lte===null?100:_age__lte} gacha bo'lgan son kiriting`
        },
       
      ]}
    >
     <InputNumber  onChange={(val)=>{if(Number(val)>=0 && ((Number(val)<=100 && _age__lte===null) || (Number(val)<=_age__lte && _age__lte!==null))){set_age__gte(Number(val))}else{set_age__gte(null)}; }} className='input' />
    </Form.Item></Col>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Yosh (gacha)"
      name="_age__lte"
      rules={[
        {
            type: 'number',
            max:100,
            min:_age__gte===null?0:_age__gte,
            message:`${_age__gte===null?0:_age__gte} va 100 gacha bo'lgan son kiriting`
        }
      ]}
    >
     <InputNumber  onChange={(val)=>{if(Number(val)<=100 && ((Number(val)>=0 && _age__gte===null) || (Number(val)>=_age__gte && _age__gte!==null))){set_age__lte(Number(val))}else{set_age__lte(null)}}} className='input' />
    </Form.Item></Col>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Tizimga qo'shilgan sana (dan)"
      name="created_at__date__gte"
      
    >
     <DatePicker onChange={(val=>{setcreated_at__date__gte(val!==null?val.format("DD.MM.YYYY"):null)})}  className='date_input' format="DD.MM.YYYY"/>
    </Form.Item></Col>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Tizimga qo'shilgan sana (gacha)"
      name="created_at__date__lte"
      
    >
     <DatePicker onChange={(val=>{setcreated_at__date__lte(val!==null?val.format("DD.MM.YYYY"):null)})} className='date_input' format="DD.MM.YYYY"/>
    </Form.Item></Col>
    </Row>
    <Form.Item
      wrapperCol={{
        span: 24,
      }}
className='btn_form_div'
    >
        <div className='btn_box'>
        <Button onClick={closeModal} htmlType="button">
        Yopish
      </Button>
      <Button onClick={resetFilter} htmlType="button">
        Tozalash
      </Button>
      <Button onClick={getData} type="primary"  htmlType="button">
        Qidirish
      </Button>
      
      </div>
    </Form.Item>
   
  </Form></div>
  )
}

import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { deleteUsersHttp, editUsersHttp, getUsersHttp, setUsersHttp } from '../host/requests/User'
import { useStore } from '../store/Store'
import { Button, Form, Input, Modal, Table } from 'antd'
import { dangerAlert, successAlert } from '../components/Alert'
import { BsPencilFill, BsTrashFill } from 'react-icons/bs'
import { IoIosWarning } from "react-icons/io";
import Pagination from '../components/Pagination'
import { changeFormatDate, noAvatar } from '../components/Checkers'

import UserFilter from '../components/UserFilter'
import { Link } from 'react-router-dom'
export default function Users() {
    const [data, setData]=useState([])
    const [open, setOpen]=useState(false)
    const [open_filter, setOpen_filter]=useState(false)
    const [modal, contextHolder] = Modal.useModal();
    const [error, setError]=useState(false)
    const [edit, setedit]=useState(null)
    const [country, setcountry]=useState(null)
    const [state, setstate]=useState(null)
    const [hobbies, sethobbies]=useState(null)
    const [gender, setgender]=useState(null)
    const [created_at__date__gte, setcreated_at__date__gte]=useState(null)
    const [created_at__date__lte, setcreated_at__date__lte]=useState(null)
    const [_age__gte, set_age__gte]=useState(null)
    const [_age__lte, set_age__lte]=useState(null)
    const [search, setsearch]=useState(null)
    const [page, setpage]=useState(1)
    const [count, setcount]=useState(0)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)
    const [form] = Form.useForm()
    useEffect(()=>{
       getData()
    },[page, search])
    const getData=async()=>{
      console.log(open_filter)
      if(open_filter){
        setpage(1)
      }
        if(search===null || search.length===0){
            setloader(true)
        }
        try{
            var res=await getUsersHttp({first_name__istartswith:search, page:open_filter?1:page,
              country:country,
              state:state,
              hobbies:hobbies,
              gender:gender,
              created_at__date__gte:created_at__date__gte,
              created_at__date__lte:created_at__date__lte,
              _age__gte:_age__gte,
              _age__lte:_age__lte
            }, token_access)
            setData(res.data.results)
            setcount(res.data.count)
            setloader(false)
            setOpen_filter(false)
        }catch (error){
            console.log(error)
            setloader(false)
        }
    }
    const openModal=()=>{
      setOpen(true)
  }
 
  const openModalFilter=()=>{
    setOpen_filter(true)
}
const closeModalFilter=()=>{
    setOpen_filter(false)
}
 
    const openModalConfirm=(id)=>{
        console.log(id)
        modal.confirm({
            title: 'Eslatma !!!',
            icon: <span className='warning_icon'><IoIosWarning /></span>,
            content: "O'chirilgan ma'lumot qaytib tiklanmaydi. Ma'lumotni o'chirishga ishonchingiz komilmi?",
            okText: 'Ha',
            cancelText: "Yo'q",
            onOk:()=>{deleteData(id)},
           
          });
    }
    const deleteData=async(id)=>{
         setloader(true)
         try{
            var res=await deleteUsersHttp(id, token_access)
            successAlert("Ma'lumot o'chirildi")
            getData()
         }catch(error){
            dangerAlert("Ma'lumot o'chirilmadi")
            setloader(false)
         }
    }
   
    const columns = [
        {
            title: '#',
            className:"mini_column",
            dataIndex: 'id',
           
            render:(data, row,index)=>{return(((page-1)*15)+(index+1))},
        },
        {
            title: '',
            className:"mini_column",
            dataIndex: '',
           
            render:(data)=>{return(<div className='table_img' style={{backgroundImage:`url(${data.main_image!==null?data.main_image.image:noAvatar(data.gender)})`}}></div>)},
        },
        {
            title: 'FIO',
            dataIndex: '',
            render:(data)=>(data.first_name+" "+data.last_name),
        },
        {
            title: "Yosh",
            dataIndex: 'age',
        },
        {
            title: "Mamlakat",
            dataIndex: 'country',
            render:(birth)=>(birth!==null?birth.name_uz:"-"),
        },
        {
            title: "Viloyat",
            dataIndex: 'state',
            render:(birth)=>(birth!==null?birth.name_uz:"-"),
        },
        {
            title: "Email",
            dataIndex: 'email',
         },
        {
            title: "Jins",
            dataIndex: 'gender',
            render:(birth)=>(birth==="male"?"Erkak":birth==="female"?"Ayol":"Aniqmas"),
        },
        {
            title: "Telefon",
            dataIndex: 'phone',
        },
        {
            title: "Tizimga qo'shilgan",
            dataIndex: 'created_at',
            render:(data)=>(changeFormatDate(data))
        },
       
        {
            title: "Likelar",
            className:"mini_column",
            dataIndex: '',
            render:(id)=>{return(<Link to={id.likes===0?"":"/users-liked-one/"+id.id}><Button disabled={id.likes===0} type="primary" onClick={()=>{openModalConfirm(id)}} shape="circle">{id.likes}</Button></Link>)},
        },
        {
            title: "Bloklaganlar",
            className:"mini_column",
            dataIndex: '',
            render:(id)=>{return(<Link to={id.blocks===0?"":"/users-blocked-one/"+id.id}><Button disabled={id.blocks===0} type="primary" onClick={()=>{openModalConfirm(id)}} shape="circle">{id.blocks}</Button></Link>)},
        },
        {
            title: "",
            className:"mini_column",
            dataIndex: 'id',
            render:(id)=>{return(<Button type="primary" onClick={()=>{openModalConfirm(id)}} danger shape="circle" icon={<BsTrashFill />} />)},
        },
           {
            title: "",
            className:"mini_column",
            dataIndex: '',
            render:(id)=>{return(<Link to={id.id}><Button type="primary" className='warning_btn' shape="circle" icon={<BsPencilFill />} /></Link>)},
           }
       
      ];
  return (
    <>
     <div className='contentHeader'>
       <Header openFilter={openModalFilter} filter={true} setpage={setpage} search={search} setsearch={setsearch}/>
     </div>
    <div className='content'>
    <div className='contentBody'>
    <Table pagination={false} rowKey={(data)=>{return(data.id)}} columns={columns} dataSource={data}/>
    {count>15?<Pagination count={count} page={page} setpage={setpage}/>:<></>}
    {contextHolder}
    <Modal  className='modal_bottom' width={"95%"} footer={false} title={`Filter`} open={open_filter} onOk={closeModalFilter} onCancel={closeModalFilter}>
   <UserFilter 
country={country}
state={state}
hobbies={hobbies}
gender={gender}
created_at__date__gte={created_at__date__gte}
created_at__date__lte={created_at__date__lte}
_age__gte={_age__gte}
_age__lte={_age__lte}
setcountry={setcountry}
setstate={setstate}
sethobbies={sethobbies}
setgender={setgender}
setcreated_at__date__gte={setcreated_at__date__gte}
setcreated_at__date__lte={setcreated_at__date__lte}
set_age__gte={set_age__gte}
set_age__lte={set_age__lte}
closeModal={closeModalFilter}
getData={getData}
setpage={setpage}
/>
    </Modal>
    </div>
    </div>
    </>
  )
}


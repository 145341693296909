import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { deleteBlockedEmailsHttp, getBlockedEmailsHttp } from '../host/requests/BlockedEmails'
import { useStore } from '../store/Store'
import { Button, Form, Input, Modal, Table } from 'antd'
import { dangerAlert, successAlert } from '../components/Alert'
import { BsTrashFill } from 'react-icons/bs'
import { IoIosWarning } from "react-icons/io";
import Pagination from '../components/Pagination'
export default function BlockedEmails() {
    const [data, setData]=useState([])
    const [modal, contextHolder] = Modal.useModal();
    const [search, setsearch]=useState(null)
    const [page, setpage]=useState(1)
    const [count, setcount]=useState(0)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)
    const [form] = Form.useForm()
    useEffect(()=>{
        getData()
    },[page, search])
    const getData=async()=>{
        if(search===null || search.length===0){
            setloader(true)
        }

        try{
            var res=await getBlockedEmailsHttp({search:search, page:page}, token_access)
            setData(res.data.results)
            setcount(res.data.count)
            setloader(false)
        }catch (error){
            console.log(error)
            setloader(false)
        }
    }
    
    
    const openModalConfirm=(id)=>{
        console.log(id)
        modal.confirm({
            title: 'Eslatma !!!',
            icon: <span className='warning_icon'><IoIosWarning /></span>,
            content: "O'chirilgan ma'lumot qaytib tiklanmaydi. Ma'lumotni o'chirishga ishonchingiz komilmi?",
            okText: 'Ha',
            cancelText: "Yo'q",
            onOk:()=>{deleteData(id)},
           
          });
    }
    const deleteData=async(id)=>{
         setloader(true)
         try{
            var res=await deleteBlockedEmailsHttp(id, token_access)
            successAlert("Ma'lumot o'chirildi")
            getData()
         }catch(error){
            dangerAlert("Ma'lumot o'chirilmadi")
            setloader(false)
         }
    }
   

    const columns = [
        {
            title: '#',
            className:"mini_column",
            dataIndex: 'id',
            render:(data, row,index)=>{return(((page-1)*15)+(index+1))},
           },
           {
            title: 'Email',
            dataIndex: 'email',
           },
           {
            title: "",
            className:"mini_column",
            dataIndex: 'id',
            render:(id)=>{return(<Button type="primary" onClick={()=>{openModalConfirm(id)}} danger shape="circle" icon={<BsTrashFill />} />)},
           }
       
      ];
  return (
    <>
     <div className='contentHeader'>
     <Header setpage={setpage} search={search} setsearch={setsearch}/>
     </div>
    <div className='content'>
    <div className='contentBody'>
    <Table pagination={false} rowKey={(data)=>{return(data.id)}} columns={columns} dataSource={data}/>
    {count>15?<Pagination count={count} page={page} setpage={setpage}/>:<></>}
   
    {contextHolder}
    </div>
    </div>
    </>
  )
}


import { httpRequestPost } from "../Host"

export const registerEmail=async(data)=>{
    var res=await httpRequestPost('/register/', data, null)
    return(res)
}
export const login=async(data)=>{
    var res=await httpRequestPost('/login/', data, null)
    return(res)
}
export const changePasswordEmail=async(data)=>{
    var res=await httpRequestPost('/reset-password-email/', data, null)
    return(res)
}
export const sendEmailCodeHttp=async(data)=>{
    var res=await httpRequestPost('/verify/', data, null)
    return(res)
}
export const resetPassword=async(data)=>{
    var res=await httpRequestPost('/reset-password/', data, null)
    return(res)
}
export const createPassword=async(data, token)=>{
    console.log(token)
    var res=await httpRequestPost('/create-password/', data, token)
    return(res)
}
import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost, httpRequestPut } from "../Host"

export const getUsersHttp=async(data, token)=>{
    var res=await httpRequestGet('/admins/users/', data, token)
    return(res)
}
export const getUserFilterHttp=async(data, token)=>{
    var res=await httpRequestGet('/admins/user/filter-options/', null, token, data)
    return(res)
}
export const setUsersHttp=async(data, token)=>{
    var res=await httpRequestPost('/admins/users/', data, token)
    return(res)
}
export const editUsersHttp=async(id, data, token)=>{
    var res=await httpRequestPatch('/admins/users/', data, token, id+"/")
    return(res)
}
export const deleteUsersHttp=async(data, token)=>{
    var res=await httpRequestDelete('/user/', data, token)
    return(res)
}
export const getUserProfile=async(data, token)=>{
    var res=await httpRequestGet('/profile/', data, token)
    return(res)
}
export const getUserFilter=async(data, token)=>{
    var res=await httpRequestGet('/filters/filter-options/', null, token, data)
    return(res)
}
export const getUsersDataRandom=async(data, token)=>{
    var res=await httpRequestGet('/filters/users/', data, token)
    return(res)
}
export const getUsersDataRandomSearch=async(data, token)=>{
    var res=await httpRequestGet('/search/users/', data, token)
    return(res)
}
export const getUserById=async(data, token)=>{
    var res=await httpRequestGet('/filters/user-detail/', data, token)
    return(res)
}
export const deleteUserImage=async(data, token)=>{
    var res=await httpRequestDelete('/admins/users/profile/images/delete/', data, token)
    return(res)
}
export const blockUserByAdmin=async(data, token)=>{
    var res=await httpRequestPost('/admins/block-users/', data, token)
    return(res)
}

export const editUserProfile=async(data, token, id)=>{
    var res=await httpRequestPatch('/admins/users/detail/', data, token, id)
    return(res)
}
export const editUserFilter=async(data, token, id)=>{
    var res=await httpRequestPatch('/admins/users/filter-options/', data, token, id)
    return(res)
}
export const addImageToUser=async(data, token)=>{
    var res=await httpRequestPost('/admins/users/profile/images/create/', data, token)
    return(res)
}
export const setUserImageMain=async(data, param, token)=>{
    var res=await httpRequestPut('/admins/users/profile/set-main-image/', data, token, param)
    return(res)
}
export const setLike=async(data, token)=>{
    var res=await httpRequestPost('/my-likes/', data, token)
    return(res)
}

export const getLikeUsers=async(data, token)=>{
    var res=await httpRequestGet('/likes/', data, token)
    return(res)
}
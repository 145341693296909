import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import dayjs from 'dayjs'
import { getBlockReasonHttp } from '../host/requests/BlockReason'

export default function UsersBlocksFilter({
    created_at__date__gte,
    created_at__date__lte,
    setcreated_at__date__gte,
    setcreated_at__date__lte,
    reason,
    setreason,
    closeModal,
    getData,
    setpage
}) {
    const [form] = Form.useForm()
    const [reset_bol, setreset_bol]=useState(false)
    const [block_reasons, setblock_reasons]=useState(null)
    const token_access=useStore(state=>state.token_access)
    useEffect(()=>{
      if(block_reasons===null){
        getBlockReasons()
    } 
      setValuesForm()
       
        if(reset_bol){
            getData()
        setreset_bol(false)
        }
    }, [reason, created_at__date__gte,created_at__date__lte,reset_bol])
    const getBlockReasons=async()=>{
      try{
        var a=[{id:-1, reason_uz:"Sabab tanlanmagan yoki qo'lda kirtilgan"}]
        var t=true
        var i=1
        while(t){
          var res=await getBlockReasonHttp({page:i}, token_access)
            a=[...a, ...res.data.results]
        if(res.data.next!==null){
          i++
        }else{
          t=false
        }
        }
          console.log(a)
          setblock_reasons(a)
     }catch(error){
          console.log(error)
     }
  }
    const setValuesForm=()=>{
         form.setFieldsValue({
            "reason":reason,
            "created_at__date__gte":created_at__date__gte!==null?dayjs(created_at__date__gte, 'DD.MM.YYYY'):null,
            "created_at__date__lte":created_at__date__lte!==null?dayjs(created_at__date__lte, 'DD.MM.YYYY'):null,
           
        })
    }
     const resetFilter=()=>{
       setreason(null)
        setcreated_at__date__gte(null)
        setcreated_at__date__lte(null)
      setreset_bol(true)
     }
     const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  return (
    <div> <Form
    name="add"
    form={form}
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
   
  >
    <Row>
   
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Blok qilingan sana (dan)"
      name="created_at__date__gte"
      
    >
     <DatePicker onChange={(val=>{setcreated_at__date__gte(val!==null?val.format("DD.MM.YYYY"):null)})}  className='date_input' format="DD.MM.YYYY"/>
    </Form.Item></Col>
    <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Blok qilingan sana (gacha)"
      name="created_at__date__lte"
      
    >
     <DatePicker onChange={(val=>{setcreated_at__date__lte(val!==null?val.format("DD.MM.YYYY"):null)})} className='date_input' format="DD.MM.YYYY"/>
    </Form.Item></Col>
     <Col style={{padding:'0.5vw'}} lg={6} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Blok qilish sababi sana (gacha)"
      name="reason"
    >
    <Select
    className='select' 
    showSearch
    optionFilterProp="children"
    filterOption={filterOption}
    onChange={(val)=>{setreason(val); console.log(val)}}
    options={block_reasons!==null?block_reasons.map((item, ket)=>({label:item[`reason_uz`], value:item.id})):[]}
    />
    </Form.Item></Col>
    </Row>
    <Form.Item
      wrapperCol={{
        span: 24,
      }}
className='btn_form_div'
    >
        <div className='btn_box'>
        <Button onClick={closeModal} htmlType="button">
        Yopish
      </Button>
      <Button onClick={resetFilter} htmlType="button">
        Tozalash
      </Button>
      <Button onClick={getData} type="primary"  htmlType="button">
        Qidirish
      </Button>
      
      </div>
    </Form.Item>
   
  </Form></div>
  )
}

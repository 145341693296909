import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHref } from 'react-router-dom/dist'
import { BsCollection } from "react-icons/bs";
import { MdInterests, MdQuestionMark } from "react-icons/md";
import { SiAdblock } from "react-icons/si";
import { IoIosArrowBack, IoIosArrowDown, IoMdChatboxes } from 'react-icons/io';
import { FaBan, FaUsers } from 'react-icons/fa';
import { TbLock } from "react-icons/tb";
import { FaHeart } from "react-icons/fa6";
export default function Menu() {
  const href=useHref()
  const {t} =useTranslation()
  const [user_drop, setuser_drop]=useState(false)
  console.log(href)
  return (
    <div className='menuDiv'>
     <div className='menuHeader'>
        Tanishuv Platforma
     </div>
     <div className='menuList'>
     <Link  className={`menuItem ${href==='/'?"active_menu":""}`} to={"/"}>
          <BsCollection /><p>Bosh sahifa</p>
        </Link>
        <div onClick={()=>{setuser_drop(!user_drop)}} className={`drop_title ${user_drop?"opened_title":""}`}><span>Foydalanuchilar</span> {user_drop?<IoIosArrowDown className='drop_icon'/>:<IoIosArrowBack className='drop_icon'/>}</div>
        <div className={`drop ${user_drop?"opened_drop_user":""}`}>
        <Link className={`menuItem ${href.indexOf("users")!==-1?"active_menu":""}`} to={"/users"}>
        <FaUsers /><p>Foydalanuchilar</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("user-chats")!==-1?"active_menu":""}`} to={"/user-chats"}>
        <IoMdChatboxes /><p>Yozishmalar</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("liked-user")!==-1?"active_menu":""}`} to={"/liked-user"}>
        <FaHeart /><p>Likelar</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("blocked-user")!==-1?"active_menu":""}`} to={"/blocked-user"}>
        <TbLock /><p>Bloklanganlar</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("blocked-emails")!==-1?"active_menu":""}`} to={"/blocked-emails"}>
        <FaBan /><p>Taqiqlanganlar</p>
        </Link>
        </div>


        <Link className={`menuItem ${href.indexOf("hobby")!==-1?"active_menu":""}`} to={"/hobby"}>
        <MdInterests /><p>Qiziqishlar</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("faq")!==-1?"active_menu":""}`} to={"/faq"}>
        <MdQuestionMark /><p>FAQ</p>
        </Link>
        <Link className={`menuItem ${href.indexOf("blocking-reasons")!==-1?"active_menu":""}`} to={"/blocking-reasons"}>
        <SiAdblock /><p>Bloklash sabablari</p>
        </Link>
     </div>
    </div>
  )
}

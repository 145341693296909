import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getBlockReasonHttp=async(data, token)=>{
    var res=await httpRequestGet('/blocking-reasons/', data, token)
    return(res)
}
export const setBlockReasonHttp=async(data, token)=>{
    var res=await httpRequestPost('/blocking-reasons/', data, token)
    return(res)
}
export const editBlockReasonHttp=async(id, data, token)=>{
    var res=await httpRequestPatch('/blocking-reasons/', data, token, id)
    return(res)
}
export const deleteBlockReasonHttp=async(data, token)=>{
    var res=await httpRequestDelete('/blocking-reasons/', data, token)
    return(res)
}
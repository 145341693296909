import maleimg from '../images/random_avatar_man.jpg'
import femaleimg from '../images/random_avatar_woman.jpg'
import otherimg from '../images/random_avatar_other.jpg'
import dayjs from 'dayjs'
export const checkUsersData=(data)=>{
    return(data!==null && data.phone!==null && data.gender!==null && data.first_name!==null && data.last_name!==null && data.birthdate!==null && data.country!==null && data.state!==null && data.hobbies.length!==0)
}

export const checkDate=(a, lan)=>{
   var inputDate = new Date(a);
    var currentDate = new Date();
    var timeDifference = currentDate - inputDate;
    var minutAgo = timeDifference / (1000 * 60);
    minutAgo = Math.floor(minutAgo);
    if(minutAgo<=0){
        return(now[lan])
    }else if(minutAgo<=60){
        return(minutAgo+" "+minut_befor[lan])
    } else{
        var hoursAgo = timeDifference / (1000 * 60 * 60);
        hoursAgo = Math.floor(hoursAgo);
       if(hoursAgo<=24){
            return(hoursAgo+" "+hour_befor[lan])
        }else{
            var daysAgo = timeDifference / (1000 * 60 * 60 * 24);
            daysAgo = Math.floor(daysAgo);
            if(daysAgo<=10){
                return(daysAgo+" "+day_befor[lan])
            }else{
                var weeksAgo = timeDifference / (1000 * 60 * 60 * 24 * 7);
                weeksAgo = Math.floor(weeksAgo);
                if(weeksAgo<=20){
                    return(weeksAgo+" "+week_befor[lan])
                }else{
                    var daysAgo = timeDifference / (1000 * 60 * 60 * 24);
                    var monthsAgo = daysAgo / 30.44;
                    monthsAgo = Math.floor(monthsAgo);
                    if(monthsAgo<=20){
                        return(monthsAgo+" "+month_befor[lan])
                    }else{
                        var yearsAgo = timeDifference / (1000 * 60 * 60 * 24 * 365.25);
                        yearsAgo = Math.floor(yearsAgo);
                            return(yearsAgo+" "+year_befor[lan])
                    }
                    
                }
            }
        }
     
    } 
    
  
   
}

const now={
    "uz":"hozirgina",
    "ru":"прямо сейчас",
    "en":"just now",
}
const minut_befor={
    "uz":"minut oldin",
    "ru":"минуту назад",
    "en":"a minute ago",
}
const week_befor={
    "uz":"hafta oldin",
    "ru":"недели назад",
    "en":"weeks ago",
}
const month_befor={
    "uz":"oy oldin",
    "ru":"месяца назад",
    "en":"months ago",
}
const year_befor={
    "uz":"yil oldin",
    "ru":"год назад",
    "en":"year ago",
}
const hour_befor={
    "uz":"soat oldin",
    "ru":"часа назад",
    "en":"hours ago",
}
const day_befor={
    "uz":"kun oldin",
    "ru":"дня назад",
    "en":"days ago",
}

export const noAvatar=(gender)=>{
    var con={
        "male":maleimg,
        "female":femaleimg,
        "rather_not_say":otherimg,
    }
    if(gender===null){
        return(otherimg)
    }else{
        gender=gender.replaceAll(" ", "_")
        return(con[gender])
    }
    

}

export function disabledDate(current) {
    const minDate = dayjs().subtract(90, 'year'); // Example: 100 years ago
    const maxDate = dayjs().subtract(18, 'year');  // Example: 18 years ago
  
    return current && (current < minDate || current > maxDate);
  }
export const findAge=(birthdate)=>{
    var birthdate = new Date("2001-11-27");
var currentDate = new Date();

// Calculate the difference in years
var age = currentDate.getFullYear() - birthdate.getFullYear();

// Check if the birthday has occurred this year
if (
  currentDate.getMonth() < birthdate.getMonth() ||
  (currentDate.getMonth() === birthdate.getMonth() &&
    currentDate.getDate() < birthdate.getDate())
) {
  age--;
}
return(age)
}

export const changeFormatDate=(birthdate)=>{
   
    var month = birthdate.slice(5,7)
    var day =   birthdate.slice(8,10)
    var year = birthdate.slice(0,4)


return [day, month, year].join('.');
}
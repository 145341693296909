import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getCountriesHttp=async(data, token)=>{
    var res=await httpRequestGet('/countries/', null, token)
    return(res)
}
export const getHobbiesHttp=async(data, token)=>{
    var res=await httpRequestGet('/hobbies/', data, token)
    return(res)
}
export const setHobbiesHttp=async(data, token)=>{
    var res=await httpRequestPost('/hobbies/', data, token)
    return(res)
}
export const editHobbiesHttp=async(id, data, token)=>{
    var res=await httpRequestPatch('/hobbies/', data, token, id)
    return(res)
}
export const deleteHobbiesHttp=async(data, token)=>{
    var res=await httpRequestDelete('/hobbies/', data, token)
    return(res)
}
export const getStatesHttp=async(data, token)=>{
    var res=await httpRequestGet('/states/', data, token)
    return(res)
}
import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getfaqHttp=async(data, token)=>{
    var res=await httpRequestGet('/faq/', data, token)
    return(res)
}
export const setfaqHttp=async(data, token)=>{
    var res=await httpRequestPost('/faq/', data, token)
    return(res)
}
export const editfaqHttp=async(id, data, token)=>{
    var res=await httpRequestPatch('/faq/', data, token, id)
    return(res)
}
export const deletefaqHttp=async(data, token)=>{
    var res=await httpRequestDelete('/faq/', data, token)
    return(res)
}